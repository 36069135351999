import { isElectron } from '@groupthinkai/groupthink';
import { Button, ButtonProps, Link } from '@mui/joy';
import * as React from 'react';

export interface AuthButtonProps extends ButtonProps {
  /**
   * The URL to redirect to for authentication.
   */
  authUrl?: string;

  /**
   * The URLSearchParams to append to the authUrl.
   */
  params?: URLSearchParams;

  /**
   * The path to redirect to after authentication.
   */
  redirectTo?: string;
}

/**
 * A Button component for handling OAuth authentication.
 * Handles driver auth redirects for web and Electron.
 */
export default function AuthButton(props: AuthButtonProps) {
  const { params, authUrl = '', redirectTo, component = Link, children, ...buttonProps } = props;

  let effectiveParams = new URLSearchParams();

  if (params) {
    effectiveParams = new URLSearchParams(params);
  }

  let redirectPath;
  if (typeof window !== 'undefined' && isElectron) {
    redirectPath = '/desktop/return';

    if (redirectTo) {
      redirectPath += '?redirect_to=' + encodeURIComponent(redirectTo);
    }
  } else if (typeof window !== 'undefined' && !redirectPath) {
    redirectPath = `${window.location.pathname}${window.location.search}`;
  }
  if (redirectPath) {
    effectiveParams.set('final_destination', redirectPath);
  }

  const finalUrl = authUrl
    ? `${process.env.NEXT_PUBLIC_BACKEND_URL}${authUrl}${
        authUrl.includes('?') ? '&' : '?'
      }${effectiveParams.toString()}`
    : '';

  const handleConnect = React.useCallback(
    (e) => {
      e.preventDefault();
      if (isElectron) {
        window.electron.openExternal(finalUrl);
      } else {
        window.location.href = finalUrl;
      }
    },
    [finalUrl, isElectron]
  );

  if (!authUrl) {
    return null;
  }

  return (
    <Button component={component} onClick={handleConnect} href={finalUrl} {...buttonProps}>
      {children ?? 'Connect'}
    </Button>
  );
}
