import { Box } from '@mui/joy';
import React, { useCallback, useLayoutEffect, useState } from 'react';

const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : () => {};

interface RemainingHeightBoxProps {
  children: React.ReactNode;
  measureRef: React.RefObject<HTMLElement>;
  fallbackHeight?: number;
  sx?: object;
}

export const RemainingHeightBox = ({ children, measureRef, sx = {} }: RemainingHeightBoxProps) => {
  const [elementHeight, setElementHeight] = useState(-1);

  const updateHeight = useCallback(() => {
    if (measureRef.current) {
      setElementHeight(measureRef.current.offsetHeight);
    }
  }, [measureRef]);

  useIsomorphicLayoutEffect(() => {
    updateHeight();

    const handleResizeLoad = () => updateHeight();
    window.addEventListener('resize', handleResizeLoad);
    window.addEventListener('load', handleResizeLoad);

    let observer: MutationObserver | undefined;
    let intervalId: NodeJS.Timeout | undefined;

    if (measureRef.current) {
      observer = new MutationObserver(updateHeight);
      observer.observe(measureRef.current, {
        subtree: true,
        childList: true,
        characterData: true,
      });
    } else {
      // Poll until the node is available
      intervalId = setInterval(() => {
        if (measureRef.current) {
          updateHeight();
          observer = new MutationObserver(updateHeight);
          observer.observe(measureRef.current, {
            subtree: true,
            childList: true,
            characterData: true,
          });
          if (intervalId) clearInterval(intervalId);
        }
      }, 50);
    }

    return () => {
      window.removeEventListener('resize', handleResizeLoad);
      window.removeEventListener('load', handleResizeLoad);
      if (observer) {
        observer.disconnect();
      }
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [updateHeight, measureRef]);

  return (
    <Box
      sx={{
        height: elementHeight >= 0 ? `calc(100% - ${elementHeight}px)` : `100%`,
        ...sx,
      }}>
      {children}
    </Box>
  );
};
