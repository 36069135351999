import { isElectron } from '@groupthinkai/groupthink';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';

// https://usehooks.com/useOnClickOutside/
export const useOnClickOutside = (ref, handler) => {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
};

export const useChromelessLayout = () => {
  const router = useRouter();
  const chromeless =
    router.query.chromeless === '1' ||
    router.query.chromeless === 1 ||
    (typeof router.query.chromeless === 'string' &&
      router.query.chromeless.toLocaleLowerCase() === 'true');

  return { isChromeless: chromeless };
};

// Usage: useWhyDidYouRender('MyComponent', { ...props, ...state, ...hookVals, ...etc });
export function useWhyDidYouRender(name, details) {
  const prevDetails = useRef();

  useEffect(() => {
    if (prevDetails.current) {
      const changes = Object.keys({ ...prevDetails.current, ...details })
        .filter((key) => prevDetails.current[key] !== details[key])
        .reduce((acc, key) => {
          acc[key] = { from: prevDetails.current[key], to: details[key] };
          return acc;
        }, {});

      if (Object.keys(changes).length) {
        console.log(`[why-did-you-render] ${name}`, changes);
      }
    }
    prevDetails.current = details;
  }, [details]); // The plot thickens with each dependency.

  // Customize as needed, not used in prod
}

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

// this function reduces the avatars to a maximum of 5
export const useClampAvatars = (avatars, options = { max: 5 }) => {
  if (!avatars) return { avatars: [], surplus: 0 };
  // destructure the options object
  const { max = 5, total } = options;
  // if the max is less than 2, set it to 2
  let clampedMax = max < 2 ? 2 : max;

  const totalAvatars = total || avatars?.length;
  // if the total avatars is equal to the clamped max, increment the clamped max by 1
  if (totalAvatars === clampedMax) {
    clampedMax += 1;
  }
  // clamped max is the minimum of the total avatars + 1 and the clamped max
  clampedMax = Math.min(totalAvatars + 1, clampedMax);
  // max avatars is the minimum of the total avatars and the clamped max - 1
  const maxAvatars = Math.min(avatars?.length, clampedMax - 1);
  // surplus is the maximum of the total avatars - the clamped max and the total avatars - the max avatars
  const surplus = Math.max(totalAvatars - clampedMax, totalAvatars - maxAvatars, 0);
  // return the avatars array sliced from 0 to the max avatars and the surplus
  return { avatars: avatars.slice(0, maxAvatars).reverse(), surplus };
};

export const copyTextToClipboard = (text) => {
  if (isElectron) {
    return new Promise((resolve) => {
      window.electron.copyTextToClipboard(text);
      resolve();
    });
  } else if (navigator?.clipboard?.writeText) {
    return navigator.clipboard.writeText(text);
  } else {
    return Promise.reject('Could not copy text to clipboard');
  }
};
