import { useOrganization, useOrganizations, useUser } from '@groupthinkai/groupthink';
import React, { useEffect, useState } from 'react';
import { useLocalStorage, useReadLocalStorage } from 'usehooks-ts';

const useLocalStorageKey = () => {
  const { user } = useUser('me');
  return 'groupthink_current_organization_' + (user?.id ?? 'noid');
};

export const useReadCurrentOrganization = () => {
  return useReadLocalStorage(useLocalStorageKey());
};

export const useCurrentOrganization = () => {
  const { organizations } = useOrganizations();
  const localStorageKey = useLocalStorageKey();
  const [stateCurrentOrganization, setStateCurrentOrganization] = useState(null);

  // Read the current organization from localStorage
  const initialOrganization = useReadLocalStorage(localStorageKey);

  // useLocalStorage hook to manage the current organization state
  const [currentOrganization, setCurrentOrganization] = useLocalStorage(
    localStorageKey,
    initialOrganization
  );

  useEffect(() => {
    // If currentOrganization is not set and organizations is loaded, update it
    if (!currentOrganization && organizations && organizations.length > 0) {
      setCurrentOrganization(organizations[0]?.id);
    }
  }, [organizations, currentOrganization, setCurrentOrganization]);

  useEffect(() => {
    setStateCurrentOrganization(currentOrganization);
  }, [currentOrganization]);

  return [stateCurrentOrganization, setCurrentOrganization];
};

/**
 * Usage:
 *
 * Pass a component as the first argument. If the current organization has access
 * to the feature name provided as the second argument, the component will be
 * rendered. Otherwise, it will return null.
 *
 * const FeatureGatedComponent = withFeature(Component, 'featureName');
 */
export const withFeature = (Component, feature) => {
  return function FeatureWrapper() {
    const [organizationId] = useCurrentOrganization();
    const { organization } = useOrganization(organizationId);

    if (!organization?.features?.[feature]) return null;

    return <React.Fragment>{Component}</React.Fragment>;
  };
};
